<template>
  <b-modal
    id="userRequestModal"
    ref="userRequestModal"
    v-model="isVisible"
    title="Create New Navigator Login"
    size="lg"
    @ok='close()'
    @hide="close()"
    @cancel='close()'
    :no-close-on-backdrop="true">
    <messages />
    <b-row>
      <b-col cols="11">
        <b-form-group
          label="Select an email"
          label-for="nested-salutation"
          label-cols-sm="3"
          label-align-sm="left"
          content-cols-md="8">
          <div style="display: flex; flex-direction: row; gap: 20px;">
            <b-form-select
              id="nested-salutation"
              v-model="$v.selectedEmailAddress.$model"
              :options="companyContactOptions"
              plain
              :disabled="inputEmailAddress !== null && inputEmailAddress !== ''">
            </b-form-select>
            <button class="close-button" @click="selectedEmailAddress = null" title="Clear selected email">x</button>
          </div>
        </b-form-group>
      </b-col>
    </b-row>

    <hr class="orChoiceSeparator">

    <!--Email-->
    <b-row>
      <b-col cols="11">
        <b-form-group
          label="Enter an email"
          label-for="nested-emailaddress"
          label-cols-sm="3"
          label-align-sm="left"
          content-cols-md="8"
          class="enter-email-row">
          <b-form-input
            id="nested-emailaddress"
            v-model.trim="$v.inputEmailAddress.$model"
            :state="!$v.inputEmailAddress.$error"
            :disabled="selectedEmailAddress !== null && selectedEmailAddress !== undefined">
          </b-form-input>
          <div
            class="text-danger"
            v-if="!$v.inputEmailAddress.required && $v.inputEmailAddress.$dirty">
            Email is required
          </div>
          <div
            class="text-danger"
            v-else-if="!$v.inputEmailAddress.email && $v.inputEmailAddress.$dirty">
            Enter a valid email address
          </div>
          <div
            class="text-danger"
            v-else-if="!$v.inputEmailAddress.maxLength && $v.inputEmailAddress.$dirty">
            Maximum of 50 characters allowed
          </div>
        </b-form-group>

      </b-col>
    </b-row>

    <template #modal-footer>
      <div class="user-request-modal-footer">
        <p>Click Submit to send an email to the address you selected/entered that will allow them to create an online Navigator Login for this company</p>
        <b-button
          variant="success"
          class="mr-1"
          size="lg"
          @click="submitUserRequest()">
          Submit
        </b-button>
      </div>
    </template>
  </b-modal>
</template>

<style lang="scss" scoped>
.is-invalid {
  border: 1px solid #dc3545 !important;
}

.enter-email-row {
  margin-top: 16px;
}
// Mobile devices
@media only screen and (max-width: 425px) {
  .enter-email-row {
    margin-top: 0;
  }
}
.user-request-modal-footer{
  display: flex;
  align-items: center;
  gap: 6rem;
}
</style>

<script>

import CompanyContactMixin from '../../mixins/CompanyContactMixin.vue'
import CompanyMixin from '../../mixins/CompanyMixin.vue'
import { validationMixin } from 'vuelidate'
import { mapMutations } from 'vuex'

import ModalMessages from '../../containers/navs/ModalMessages.vue'

const {
  required,
  requiredIf,
  maxLength,
  email
} = require('vuelidate/lib/validators')

export default {
  name: 'contactModal',
  components: {
    messages: ModalMessages
  },

  mixins: [CompanyMixin, CompanyContactMixin, validationMixin],
  validations: {
    selectedEmailAddress: {
      required: requiredIf(function () {
        return this.inputEmailAddress === null && this.inputEmailAddress === undefined && this.inputEmailAddress === ''
      })
    },
    inputEmailAddress: {
      required: requiredIf(function () {
        return this.selectedEmailAddress === null && this.selectedEmailAddress === undefined
      }),
      email,
      maxLength: maxLength(50)
    }
  },
  data: () => ({
    isVisible: false,
    companyID: 0,
    companyContactOptions: [],
    selectedEmailAddress: null,
    inputEmailAddress: ''
  }),

  methods: {
    ...mapMutations([
      'setModalDangerMessage',
      'setModalInfoMessage'
    ]),
    async show (opts = {}) {
      if (opts.exitFunction) {
        this.exitFunction = opts.exitFunction
      }

      if (opts.companyID) {
        this.companyID = opts.companyID
        await this.getCompanyContactsByCompanyID(this.companyID)
        this.mapCompanyContacts()
      }

      this.isVisible = true
    },
    close () {
      if (this.exitFunction) {
        this.exitFunction()
      }

      this.companyID = 0
      this.companyContactOptions = []
      this.selectedEmailAddress = null
      this.inputEmailAddress = ''
      this.setModalDangerMessage('')
      this.setModalInfoMessage('')
      this.isVisible = false
    },
    async submitUserRequest () {
      this.$v.$touch()
      if (!this.$v.$error) {
        await this.sendUserRequest(this.companyID, this.selectedEmailAddress !== null ? this.selectedEmailAddress : this.inputEmailAddress)
        if (this.statusCode === 200) {
          this.close()
        }
      }
    },
    mapCompanyContacts () {
      this.companyContactOptions = this.companyContacts.map((c) => {
        return {
          text: c.emailAddress,
          value: c.emailAddress
        }
      })
    }
  }
}
</script>
