<script>
import axios from 'axios'
import { BeaconapiUrl } from '../constants/config'
import { mapMutations } from 'vuex'
import { instance } from '../../src/request/app-axios.js'

export default {
  data () {
    return {
      company: {},
      companyContacts: [],
      companies: [],
      companyContact: {},
      contactPhone: {},
      salutation: [],
      contactTypeOptions: [],
      statusCode: null,
      companyContactData: []
    }
  },

  methods: {
    ...mapMutations([
      'setDangerMessage',
      'setAlertMessage',
      'setInfoMessage',
      'setModalDangerMessage',
      'setModalAlertMessage',
      'setModalInfoMessage',
      'appendInfoMessage',
      'appendDangerMessage'
    ]),
    async getCompanyContactByContactID (contactID) {
      await axios
        .get(`${BeaconapiUrl}/api/company-contact/${contactID}`)
        .then((result) => {
          this.companyContact = result.data
        })
        .catch((error) => {
          this.setModalDangerMessage(error)
        })
    },
    async getCompanyContactsByCompanyID (companyID) {
      await axios
        .get(`${BeaconapiUrl}/api/company-contact?companyID=${companyID}`)
        .then((result) => {
          this.companyContacts = result.data
        })
        .catch((error) => {
          this.setDangerMessage(error)
        })
    },
    async updateCompanyContact (contact) {
      contact.lastModifiedByID = localStorage.getItem('employeeID')
      return await axios
        .put(`${BeaconapiUrl}/api/company-contact`, contact)
        .then((result) => {
          this.statusCode = result.status
          this.setInfoMessage(`Successfully updated Contact ${contact.firstName} ${contact.lastName}`)
        })
        .catch((error) => {
          this.statusCode = error.response.status
          this.setDangerMessage(error.response.data)
        })
    },

    async addCompanyContact (contact) {
      return await instance
        .post(`${BeaconapiUrl}/api/company-contact`, contact)
        .then((result) => {
          this.statusCode = result.status
          this.setModalInfoMessage(`Successfully Added ${contact.firstName} ${contact.lastName}`)
          return result.data
        })
        .catch((error) => {
          this.statusCode = error.response.status
          this.setModalDangerMessage(error.response.data)
        })
    },
    async deleteCompanyContact (contactID) {
      await axios
        .delete(`${BeaconapiUrl}/api/company-contact/${contactID}`)
        .then((result) => {
          this.statusCode = result.status
          this.setInfoMessage(result.data)
        })
        .catch((error) => {
          this.appendDangerMessage(error)
        })
    },
    async getCompanyContactPhoneByContactId (contactID) {
      await axios
        .get(`${BeaconapiUrl}/api/company-contact/phone`, {
          params: { contactID: contactID }
        })
        .then((result) => {
          this.contactPhone = result.data
        })
        .catch((error) => {
          this.setDangerMessage(error)
        })
    },

    async updateCompanyContactPhone (contact) {
      var numberWithMask = contact.phoneNumber
      contact.phoneNumber = this.removePhoneMask(contact.phoneNumber)
      await axios
        .put(`${BeaconapiUrl}/api/company-contact/phone`, contact)
        .then((result) => {
          this.statusCode = result.status
          this.setModalInfoMessage(`Successfully updated phone number ${numberWithMask}`)
        })
        .catch((error) => {
          this.setModalDangerMessage(error)
        })
    },
    async addCompanyContactPhone (companyContactPhone) {
      var numberWithMask = companyContactPhone.phoneNumber
      companyContactPhone.phoneNumber = this.removePhoneMask(companyContactPhone.phoneNumber)
      await axios
        .post(`${BeaconapiUrl}/api/company-contact/phone`, companyContactPhone)
        .then((result) => {
          this.statusCode = result.status
          this.setModalInfoMessage(`Successfully added phone number ${numberWithMask}`)
        })
        .catch((error) => {
          this.setModalDangerMessage(error)
        })
    },
    async deleteCompanyContactPhone (companyContactPhoneID) {
      return await axios
        .delete(`${BeaconapiUrl}/api/company-contact/phone/${companyContactPhoneID}`)
        .then((result) => {
          this.statusCode = result.status
          this.setModalInfoMessage(result.data)
        })
        .catch((error) => {
          this.setModalDangerMessage(error)
        })
    },
    async getAllSalutation () {
      return await axios
        .get(`${BeaconapiUrl}/api/company-contact/salutation`)
        .then((result) => { this.salutation = result.data })
        .catch((error) => {
          this.setModalDangerMessage(error)
        })
    },
    // Contact Types
    async getContactTypeOptions () {
      await axios
        .get(`${BeaconapiUrl}/api/contact-type/by-program`)
        .then((result) => {
          this.contactTypeOptions = result.data
        })
        .catch((error) => {
          this.setModalDangerMessage(error)
        })
    },
    async getActivityContactDataByCompanyId (companyID) {
      await axios
        .get(`${BeaconapiUrl}/api/company-contact/activity-contact-data`, { params: { companyID: companyID } })
        .then((result) => {
          this.companyContactData = result.data
        })
        .catch((error) => {
          this.setDangerMessage(error)
        })
    }
  }
}
</script>
