import axios from 'axios'

const instance = axios.create({
  transformRequest: [
    (data, headers) => {
      data = {
        ...data,
        // who: 'itsme',
        createdByID: null,
        dateCreated: null,
        createdByAspNetUserID: null
      }
      return data
    },
    ...axios.defaults.transformRequest
  ]
})

instance.interceptors.request.use(
  request => {
    // Find token in localstorage
    var tokenKey = Object.keys(localStorage).find(x => x.includes('idtoken'))
    var token = JSON.parse(localStorage.getItem(tokenKey)).secret

    console.log('get token ', token)
    request.headers.Authorization = `Bearer ${token}`
    return request
  },
  error => {
    return Promise.reject(error)
  }
)

export { instance }
