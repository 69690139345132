<script>
import axios from 'axios'
import { BeaconapiUrl } from '../constants/config'
import { mapMutations } from 'vuex'
import { instance } from '../../src/request/app-axios.js'

export default {
  data () {
    return {
      statusCode: null,
      companies: [],
      company: {},
      leadTypes: [],
      timeframes: [],
      businessLines: [],
      programsOfInterestOptions: [],
      leadsDashboard: {},
      salesMember: {},
      employees: [],
      leadDescriptions: [],
      leadReferralTypes: [],
      searchResults: [],
      advancedSearchResults: [],
      companyPotentialDuplicate: {},
      achcuProducts: [],
      dataChangeDetails: []
    }
  },
  methods: {
    ...mapMutations([
      'setDangerMessage',
      'setAlertMessage',
      'setWarningMessage',
      'setInfoMessage',
      'appendInfoMessage',
      'appendDangerMessage',
      'setModalDangerMessage'
    ]),
    async tableFetch (apiUrl, httpOptions) {
      return axios.get(apiUrl, httpOptions)
    },
    async getChangeRequestByID (changeRequestID) {
      await axios
        .get(`${BeaconapiUrl}/api/ChangeRequest/${changeRequestID}`)
        .then(result => {
          this.dataChangeDetails = result.data.changeRequestFields
        })
        .catch(error => {
          this.setDangerMessage(error)
        })
    },
    async getAllCompanies () {
      await axios
        .get(`${BeaconapiUrl}/api/company`)
        .then(result => {
          this.companies = result.data
        })
        .catch(error => {
          this.setDangerMessage(error)
        })
    },
    async getCompanyByID (companyID) {
      await axios
        .get(`${BeaconapiUrl}/api/Company/${companyID}`)
        .then(result => {
          this.company = result.data
        })
        .catch(error => {
          this.setDangerMessage(error)
        })
    },
    async updateCompany (company) {
      // set lastModifiedByID to current user
      company.LastModifiedByID = localStorage.getItem('employeeID')
      await axios
        .put(`${BeaconapiUrl}/api/Company/${company.companyID}`, company)
        .then(result => {
          this.statusCode = result.status
          if (this.statusCode === 200) {
            this.setInfoMessage(
              `Company ${company.companyID} succesfully updated!`
            )
          }
        })
        .catch(error => {
          this.statusCode = error.response.status
          this.setDangerMessage(error)
        })
    },
    async addNewCompany (company) {
      // set CreatedByID to current user
      await instance
        .post(`${BeaconapiUrl}/api/company`, company)
        .then(result => {
          this.statusCode = result.status
          if (this.statusCode === 200) {
            company.companyID = result.data.companyID
            this.setInfoMessage(
              `Company ${company.companyID} successfully created!`
            )
          }
        })
        .catch(error => {
          // TZ - If we have a server side error and return BadRequest, then, the .then promise clause above will not fire.
          // We will go directly into this catch clause.
          this.statusCode = error.response.status
          this.setDangerMessage(error)
        })
    },
    async quickAddNewCompany (company) {
      // set CreatedByID to current user
      if (company.phone.phoneNumberExtension === '') {
        company.phone.phoneNumberExtension = null
      }
      return await instance
        .post(`${BeaconapiUrl}/api/company/quick-add`, company)
        .then(result => {
          company.companyID = result.data.companyID
          return result
        })
        .catch(error => {
          this.statusCode = error.response.status
          this.setDangerMessage(error)
        })
    },
    async deleteCompany (companyID) {
      await axios
        .delete(`${BeaconapiUrl}/api/company/${companyID}`)
        .then(result => {
          this.statusCode = result.status
          this.setInfoMessage(result.data)
          this.refreshVueTable()
        })
        .catch(error => {
          this.appendDangerMessage(error)
        })
    },
    async getLeadTypes () {
      await axios
        .get(`${BeaconapiUrl}/api/lead-referential?tableName=LeadType`)
        .then(result => {
          this.leadTypes = result.data
        })
        .catch(error => {
          this.setDangerMessage(error)
        })
    },
    async getTimeframes () {
      await axios
        .get(
          `${BeaconapiUrl}/api/lead-referential?tableName=AccreditationCompletedBy`
        )
        .then(result => {
          this.timeframes = result.data
        })
        .catch(error => {
          this.setDangerMessage(error)
        })
    },
    async getBusinessLines () {
      await axios
        .get(`${BeaconapiUrl}/api/lead-referential?tableName=BusinessLine`)
        .then(result => {
          this.businessLines = result.data
        })
        .catch(error => {
          this.setDangerMessage(error)
        })
    },
    async getProgramsOfInterestOptions () {
      await axios
        .get(`${BeaconapiUrl}/api/program?active=true`)
        .then(result => {
          this.programsOfInterestOptions = result.data
        })
        .catch(error => {
          this.setDangerMessage(error)
        })
    },
    async getLeadsDashboard (employeeID) {
      return await axios
        .get(`${BeaconapiUrl}/api/lead/dashboard/${employeeID}`)
        .then(result => {
          this.leadsDashboard = result.data
        })
        .catch(error => {
          this.setDangerMessage(error)
        })
    },
    async getEmployeeByID () {
      return await axios
        .get(`${BeaconapiUrl}/api/authorization/get-employee{employeeID}`)
        .then(result => {
          this.salesMember = result.data
        })
        .catch(error => {
          this.setDangerMessage(error)
        })
    },
    async getAllSalesMembers () {
      return await axios
        .get(`${BeaconapiUrl}/api/authorization/role/employees`, { params: { roleID: 3 } })
        .then(result => {
          this.employees = result.data
        })
        .catch(error => {
          this.setDangerMessage(error)
        })
    },
    async getLeadDescriptions () {
      return await axios
        .get(`${BeaconapiUrl}/api/company/lead-descriptions`)
        .then(result => {
          this.leadDescriptions = result.data
        })
        .catch(error => {
          this.setDangerMessage(error)
        })
    },
    async getLeadReferralTypes () {
      await axios
        .get(`${BeaconapiUrl}/api/lead-referential?tableName=LeadReferralType`)
        .then(result => {
          this.leadReferralTypes = result.data
        })
        .catch(error => {
          this.setDangerMessage(error)
        })
    },
    async sendUserRequest (companyID, email) {
      return await axios
        .get(`${BeaconapiUrl}/api/company/request-login/${companyID}/${window.btoa(email)}
`)
        .then(result => {
          this.statusCode = result.status
          this.setInfoMessage(result.data)
        })
        .catch(error => {
          this.setModalDangerMessage(error)
        })
    },
    async getCompanyDuplicates (companyID) {
      await axios
        .get(`${BeaconapiUrl}/api/company/${companyID}/duplicate`)
        .then(result => {
          this.searchResults = result.data
        })
        .catch(error => {
          this.setDangerMessage(error)
        })
    },
    async UpdateCompanyDuplicate (companyPotentialDuplicate, type) {
      // valid types = old, new, acknowledge
      companyPotentialDuplicate.acknowledgedBy = localStorage.getItem(
        'employeeID'
      )
      var leadID =
        type === 'old' || type === 'acknowledge'
          ? companyPotentialDuplicate.existingCompanyID
          : companyPotentialDuplicate.newCompanyID
      await axios
        .put(
          `${BeaconapiUrl}/api/company/${companyPotentialDuplicate.newCompanyID}/duplicate/${leadID}?type=${type}`,
          companyPotentialDuplicate
        )
        .then(result => {
          var messageType =
            type === 'old' || type === 'new' ? 'closed' : 'acknowledged'
          this.appendInfoMessage(`Lead: ${leadID} successfully ${messageType}`)
        })
        .catch(error => {
          this.setDangerMessage(error)
        })
    },
    async getAdvancedSearchResults (searchText) {
      await axios
        .get(`${BeaconapiUrl}/api/search/advanced-search/${searchText}`)
        .then(result => {
          this.searchResults = result.data
        })
        .catch(error => {
          this.setDangerMessage(error)
        })
    },
    async getNewAdvancedSearchResults (searchText) {
      await axios
        .get(`${BeaconapiUrl}/api/search/dynamic-search/table?${searchText}`)
        .then(result => {
          this.advancedSearchResults = result.data.data
        })
        .catch(error => {
          this.setDangerMessage(error)
        })
    },
    async addUserToCompany (payload, userName) {
      await axios
        .post(
          `${BeaconapiUrl}/api/authorization/${payload.userID}/AddCompany/${payload.companyid}`,
          payload
        )
        .then(result => {
          this.statusCode = result.status
          if (this.statusCode === 200) {
            this.setInfoMessage(
              `Company : ${payload.companyid} succesfully added to ${userName} login!`
            )
          }
        })
        .catch(error => {
          this.setDangerMessage(error)
        })
    },
    async getAchcuProducts () {
      await axios
        .get(`${BeaconapiUrl}/api/company/achcu-products`)
        .then(result => {
          this.achcuProducts = result.data
        })
        .catch(error => {
          this.setDangerMessage(error)
        })
    },
    async updateApplyReason (changeRequestID, appliedDeniedReason) {
      await axios
        .put(
          `${BeaconapiUrl}/api/ChangeRequest/apply/${changeRequestID}`,
          appliedDeniedReason
        )
        .then(result => {
          this.statusCode = result.status
          this.setInfoMessage('Applied Reason updated successfully!')
        })
    },
    async updateDenyReason (changeRequestID, appliedDeniedReason) {
      await axios
        .put(
          `${BeaconapiUrl}/api/ChangeRequest/deny/${changeRequestID}`,
          appliedDeniedReason
        )
        .then(result => {
          this.statusCode = result.status
          this.setInfoMessage('Denied Reason updated successfully!')
        })
    }
  }
}
</script>
